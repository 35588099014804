import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: 0;

  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
    padding-top: var(--nav-height);
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 5px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 740px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1 style={{ color: '#1b263b', marginBottom: '-2px' }}>Hola, mi nombre es</h1>;
  const two = (
    <h3 className="big-heading" style={{ fontSize: '50px', color: 'rgb(0, 92, 230)' }}>
      Víctor Martín<span style={{ color: 'black' }}>.</span>
    </h3>
  );
  const three = (
    <h3 className="big-heading" style={{ lineHeight: '1' }}>
      Construyo sitios web que traen el <span style={{ color: '#005ce6' }}>éxito a tu negocio</span>
      .
    </h3>
  );
  const four = (
    <>
      <p>
        Soy un desarrollador de software fullstack especializado en el stack MERN y con experiencia
        en tecnologías relacionadas con WebGL.
      </p>
    </>
  );
  const five = (
    <a className="email-link" href="mailto:vms1995@hotmail.com" target="_blank" rel="noreferrer">
      Contáctame
    </a>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition
                style={{ marginTop: '15px' }}
                key={i}
                classNames="fadeup"
                timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
